import React, { useState, useEffect } from 'react';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import { Typography } from '@mui/material';
import styles from './styles.module.scss';
import {
    Box, Card, CardContent,
    TextField, Button, FormControl,
    Grid, Select, MenuItem, InputLabel, FormHelperText
} from '@material-ui/core';
import Loader from '@components/Loader';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { showToast } from '@redux/action/index';
import { useNavigate } from 'react-router-dom';
import { getImageUrl, postApiReq, getApiReq } from 'src/utils/ApiHandlers';
import { isYupError, parseYupError } from "@utils/Yup";
import { associateHeroInMovieValidation } from 'src/utils/validation';
const initialState = {
    heroId: '',
    movieId: '',
    agencyId: '',
    asset: [{ name: '', supply: 0, layer: 0, rarity: 0, asset: { implementation: '', presentation: '' } }],
}
const supplyRegx = /^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/;
export default function AssociateHero() {
    const { id, movieName, agencyId } = useParams();
    const [form, setForm] = useState(initialState)
    const [errors, setErrors] = useState({})
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [heroList, setHeroList] = useState([]);
    const [heroAssetList, setHeroAssetList] = useState();

    const uploadFile = async (e, index, type) => {
        let data = new FormData();
        data.append('asset', e.target.files[0])
        let image = await getImageUrl(data)

        let temp = form.asset.slice(0);
        temp[index]['asset'][type] = image


        setForm({
            ...form, asset: temp
        });

        if (errors.asset) {
            let tempError = errors.asset.slice(0)
            tempError[index]['asset'][type] = ''
            setErrors({
                ...errors, asset: tempError
            })


        }

    }
    const getHeroList = () => {
        setIsLoading(true);
        getApiReq(`/admin/get-heros-list`)
            .then(res => {
                if (res) {
                    setHeroList(res.data)
                    setIsLoading(false);
                }
                else {
                    setIsLoading(false);
                }
            }).catch(e => { console.log(e) })
    };

    const getHeroAsset = (id) => {
        getApiReq(`/admin/hero-asset-list/${id}`)
            .then(res => {
                if (res) {
                    setHeroAssetList(res.data)
                    setIsLoading(false);
                }
                else {
                    setIsLoading(false);
                }
            }).catch(e => { console.log(e) })
    };

    const handleSubmit = async () => {
        setIsLoading(true);
        let data = {
            ...form,
            movieId: id,
            agencyId: agencyId
        }
        try {
            setErrors({});
            await associateHeroInMovieValidation.validate(data, {
                abortEarly: false,
            });
            postApiReq(`/admin/heros-associate`, data)
                .then(res => {
                    if (res.status) {
                        dispatch(showToast({ message: 'Hero associated successfully', type: 'success' }))
                        setForm(initialState);
                        navigate('/movies')
                        setIsLoading(false);
                    }
                    else {
                        dispatch(showToast({ message: res.error, type: 'error' }))
                        setIsLoading(false);
                    }
                }).catch(e => { console.log(e) })
        } catch (error) {
            if (isYupError(error)) {
                setErrors(parseYupError(error));
            } else {

            }
            setIsLoading(false)
        }
    };

    const handleChange = (e) => {
        let { name, value } = e.target
        setForm({
            ...form, [name]: value
        })

        setErrors({
            ...errors, [name]: ''
        })
    }

    useEffect(() => {
        getHeroList()
    }, []);


    useEffect(() => {
        if (id) {
            setForm({ ...form, heroId: id });
        }
    }, [id])


    const addAsset = () => {
        let temp = form.asset.slice(0);
        temp.push({ name: '', supply: 0, layer: temp.length, rarity: 0, asset: { implementation: '', presentation: '' } })
        setForm({
            ...form, asset: temp
        })
    }

    const RemoveAsset = (index) => {
        let temp = form.asset.slice(0);
        temp.splice(index, 1)

        setForm({
            ...form, asset: temp
        })
    }

    const handleAssetChange = (e, index) => {
        let temp = form.asset.slice(0);
        if (e.target.name === 'supply' || e.target.name === 'rarity' || e.target.name === 'layer') {
            if (supplyRegx.test(e.target.value) || e.target.value === '') {
                temp[index][e.target.name] = e.target.value

                setForm({
                    ...form, asset: temp
                })
                if (errors.asset) {
                    let tempError = errors.asset.slice(0);
                    tempError[index][e.target.name] = ''
                    setErrors({
                        ...errors, asset: tempError
                    })
                }
            }
        }
        else {
            temp[index][e.target.name] = e.target.value
            if (errors.asset) {
                let tempError = errors.asset.slice(0);

                tempError[index][e.target.name] = ''
                setErrors({
                    ...errors, asset: tempError
                });
            }
        }

        setForm({
            ...form, asset: temp
        });

    }

    return (
        <React.Fragment>
            <TableContainer component={Paper} className={styles.tableContainer}>
                <Typography component={'h1'} className={styles.heading}>{'Associate Hero in Movie'}</Typography>
                <p className={styles.paragraph}>A series of games or contests that make up a single unit of competition.</p>
                <Box mt={3}>
                    <Card>
                        <CardContent >
                            <Box ml={10}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} lg={5} >
                                        <TextField
                                            margin="normal"
                                            fullWidth
                                            error={errors.movieId}
                                            helperText={errors.movieId}
                                            type="text"
                                            label="Movie Name"
                                            name="movieName"
                                            disabled
                                            value={movieName}
                                        />
                                    </Grid>
                                    <Grid mt={2} item xs={12} lg={5}>
                                        <FormControl fullWidth error={errors.heroId}>
                                            <InputLabel id="demo-simple-select-label">Select Hero</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={form.heroId}
                                                name="heroId"
                                                label="Select Hero"
                                                onChange={handleChange}
                                            >
                                                {heroList.map((item, index) => <MenuItem key={index} value={item._id} onClick={() => getHeroAsset(item._id)}>{item.name}</MenuItem>)}
                                            </Select>
                                            <FormHelperText>{errors.heroId}</FormHelperText>
                                        </FormControl>
                                    </Grid>
                                    {form.asset.map((item, index) => (
                                        <Grid mt={5} key={index} container spacing={3}>
                                            <Grid item xs={12} lg={3} mt={2}>
                                                <FormControl fullWidth error={errors.asset?.[index]?.name}>
                                                    <InputLabel id="demo-simple-select-label">Asset name</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={item.name}
                                                        name="name"
                                                        label="Asset name"
                                                        onChange={(e) => handleAssetChange(e, index)}
                                                    >
                                                        {heroAssetList?.assetType?.map((item, index) =>
                                                            <MenuItem key={index} value={item.name}>{item.name}</MenuItem>)}

                                                    </Select>
                                                    <FormHelperText>{errors.asset?.[index]?.name}</FormHelperText>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} lg={3} >
                                                <TextField
                                                    margin="normal"
                                                    fullWidth
                                                    type="text"
                                                    label="supply"
                                                    error={errors.asset?.[index]?.supply}
                                                    helperText={errors.asset?.[index]?.supply}
                                                    name="supply"
                                                    value={item.supply}
                                                    onChange={(e) => handleAssetChange(e, index)}
                                                />
                                            </Grid>
                                            <Grid item xs={12} lg={3} >
                                                <TextField
                                                    margin="normal"
                                                    fullWidth
                                                    error={errors.asset?.[index]?.layer}
                                                    type="text"
                                                    label="layer"
                                                    helperText={errors.asset?.[index]?.layer}
                                                    name="layer"
                                                    value={item.layer}
                                                    onChange={(e) => handleAssetChange(e, index)}
                                                />
                                            </Grid>
                                            <Grid item xs={12} lg={3} >
                                                <TextField
                                                    margin="normal"
                                                    fullWidth
                                                    error={errors.asset?.[index]?.rarity}
                                                    type="text"
                                                    label="Rarity"
                                                    helperText={errors.asset?.[index]?.rarity}
                                                    name="rarity"
                                                    value={item.rarity}
                                                    onChange={(e) => handleAssetChange(e, index)}
                                                />
                                            </Grid>
                                            <Grid item xs={12} lg={3} className={styles.imagesContainer}>
                                                <Button
                                                    className={styles.uploadButtonOne}
                                                    variant="outlined"
                                                    component="label"
                                                >
                                                    Upload implementation Image
                                                    <input
                                                        type="file"
                                                        name="asset"
                                                        onChange={(e) => uploadFile(e, index, 'implementation')}
                                                        hidden
                                                    />
                                                </Button>
                                                {item.asset.implementation && <img className={styles.image} src={item.asset.implementation} alt="implementation" />}
                                                {errors.asset?.[index]?.asset?.implementation && <small className='errormsg'>{errors.asset?.[index]?.asset?.implementation}</small>}
                                            </Grid>
                                            <Grid item xs={12} lg={3} className={styles.imagesContainer}>
                                                <Button
                                                    className={styles.uploadButtonOne}
                                                    variant="outlined"
                                                    component="label"
                                                >
                                                    Upload presentation Image
                                                    <input
                                                        type="file"
                                                        name="asset"
                                                        onChange={(e) => uploadFile(e, index, 'presentation')}
                                                        hidden
                                                    />
                                                </Button>
                                                {item.asset.presentation && <img className={styles.image} src={item.asset.presentation} alt="presentation" />}
                                                {errors.asset?.[index]?.asset?.presentation && <small className='errormsg'>{errors.asset?.[index]?.asset?.presentation}</small>}
                                            </Grid>

                                            {index > 0 && <Grid display='grid' alignItems='center' item xs={12} lg={3}><Typography component={'span'} onClick={() => RemoveAsset(index)} className={styles.removeButton}>Remove asset</Typography></Grid>}
                                        </Grid>

                                    ))}
                                    <Grid item xs={11} align="left">
                                        <Typography component={'span'} className={styles.addButton} onClick={addAsset}>Add more asset</Typography>
                                    </Grid>
                                    <Grid item xs={11} align="right">
                                        <Button size="large" variant="contained" onClick={handleSubmit}>Submit</Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </CardContent>
                    </Card>
                </Box>
                <Loader isLoading={isLoading} />
            </TableContainer >
       </React.Fragment >
    );
}
