import * as yup from "yup";

export const createFanCardValidation = yup.object().shape({
  title: yup.string().required("Please enter Sport card title."),
  tournamentName: yup.string().required("Please select tournament name."),
  teamName: yup.string().required("Please select team name."),
  year: yup.string().required("Please select tournament year."),
  description: yup
    .string()
    .required("Please enter fancard description.")
    .max(1000, "Description cannot be longer than 1000 characters"),
  asset: yup.array().of(
    yup.object().shape({
      name: yup.string().required("Please enter asset name"),
      value: yup.string().required("Please upload image"),
    })
  ),
  fancardImage: yup.string().required("Please select implementation image "),
  totalNFT: yup
    .number()
    .required("Please enter total supply")
    .min(1, "Asset supply should be greater then 1"),
  chain: yup.string().required("Please select chain"),
  price: yup
    .number()
    .required("Please enter Sport card price")
    .min(0.00001, "price should be greater then 0.00001"),
});

export const createCollectiblePackValidation = yup.object().shape({
  tournamentId: yup.string().required("Please select tournament name."),
  sportId: yup.string().required("Please select sport name."),
  collectionName: yup.string().required("Please enter collection name."),
  asset: yup.string().required("Please upload collection image."),
  year: yup.string().required("Please select tournament year."),
  supply: yup
    .number()
    .required("Please enter pack supply.")
    .min(1, "Asset supply should be greater then 1"),
  description: yup
    .string()
    .required("Please enter pack description.")
    .max(1000, "Description cannot be longer than 1000 characters"),
  cardInside: yup
    .number()
    .required("Please enter card inside count.")
    .min(1, "Card inside should be greater then 1"),
  chain: yup.string().required("Please select chain ."),
  price: yup
    .number()
    .required("Please enter fan card price")
    .min(0.00001, "price should be greater then 0.00001"),
  packDrop: yup.string().required("Please select pack drop Time."),
});

export const createEntertainmentPackValidation = yup.object().shape({
  agencyId: yup.string().required("Please select agency name."),
  collectionName: yup.string().required("Please enter collection name."),
  asset: yup.string().required("Please upload entertainment image."),
  year: yup.string().required("Please select agency year."),
  // selectAgency: yup.string().required("Please select select Agency."),
  movieId: yup.string().required("Please select select Movie."),
  supply: yup
    .number()
    .required("Please enter pack supply.")
    .min(1, "Asset supply should be greater then 1"),
  description: yup
    .string()
    .required("Please enter pack description.")
    .max(1000, "Description cannot be longer than 1000 characters"),
  cardInside: yup
    .number()
    .required("Please enter card inside count.")
    .min(1, "Card inside should be greater then 1"),
  chain: yup.string().required("Please select chain ."),
  price: yup
    .number()
    .required("Please enter fan card price")
    .min(0.00001, "price should be greater then 0.00001"),
  packDrop: yup.string().required("Please select pack drop Time."),
});

export const createTournamentValidation = yup.object().shape({
  tournamentName: yup.string().required("Please enter tournament name"),
  year: yup.string().required("Please select tournament year"),
  description: yup
    .string()
    .required("Please enter description")
    .max(1000, "Description cannot be longer than 1000 characters"),
  sportId: yup.string().required("Please select sport type"),
});

export const createAgencyValidation = yup.object().shape({
  agencyName: yup.string().required("Please enter agency name"),
  year: yup.string().required("Please select agency year"),
  description: yup
    .string()
    .required("Please enter description")
    .max(1000, "Description cannot be longer than 1000 characters"),
});

export const createPlayerValidation = yup.object().shape({
  playerName: yup.string().required("Please enter player name"),
  profile: yup.string().required("Please upload player profile image"),
});

export const createTeamValidation = yup.object().shape({
  teamName: yup.string().required("Please enter team name"),
  description: yup
    .string()
    .required("Please enter team description")
    .max(1000, "Description cannot be longer than 1000 characters"),
  logo: yup.string().required("Please upload team logo image"),
  coverImage: yup.string().required("Please upload team cover image"),
  sportId: yup.string().required("Please select sport type"),
});

export const createMovieValidation = yup.object().shape({
  movieName: yup.string().required("Please enter movie name"),
  description: yup
    .string()
    .required("Please enter movie description")
    .max(1000, "Description cannot be longer than 1000 characters"),
  logo: yup.string().required("Please upload movie logo image"),
  coverImage: yup.string().required("Please upload movie cover image"),
  agencyId: yup.string().required("Please select agency name"),
});

export const createSuperHeroValidation = yup.object().shape({
  name: yup.string().required("Please enter hero name"),
  profile: yup.string().required("Please upload heros profile image"),
  assetType: yup.array().of(
    yup.object().shape({
      name: yup.string().required("Please enter asset name"),
    })
  ),
});

export const uploadTeamAssetValidation = yup.object().shape({
  tournamentId: yup.string().required("Please select tournament name"),
  year: yup.string().required("Please select tournamnet year"),
  assetHome: yup.array().of(
    yup.object().shape({
      name: yup.string().required("Please enter asset name"),
      supply: yup
        .number()
        .required("Please enter asset supply")
        .min(1, "Asset supply should be greater then 1"),
      layer: yup.number().required("Please enter asset layer"),
      rarity: yup.number().required("Please enter asset rarity"),
      asset: yup.object().shape({
        implementation: yup
          .string()
          .required("Please select implementation image "),
        implementation_1: yup
          .string()
          .required("Please select implementation image "),
        presentation: yup.string().required("Please enter presentation image"),
      }),
    })
  ),
  assetAway: yup.array().of(
    yup.object().shape({
      name: yup.string().required("Please enter asset name"),
      supply: yup
        .number()
        .required("Please enter asset supply")
        .min(1, "Asset supply should be greater then 1"),
      layer: yup.number().required("Please enter asset layer"),
      rarity: yup
        .number()
        .required("Please enter asset rarity")
        .min(1, "Rarity should be greater then 1")
        .max(10, "Rarity should be 10 or lower then 10"),
      asset: yup.object().shape({
        implementation: yup
          .string()
          .required("Please select implementation image "),
        implementation_1: yup
          .string()
          .required("Please select implementation image "),
        presentation: yup.string().required("Please enter presentation image"),
      }),
    })
  ),
});

export const uploadMovieAssetValidation = yup.object().shape({
  agencyId: yup.string().required("Please select agency name"),
  year: yup.string().required("Please select agency year"),
  asset: yup.array().of(
    yup.object().shape({
      name: yup.string().required("Please enter asset name"),
      supply: yup
        .number()
        .required("Please enter asset supply")
        .min(1, "Asset supply should be greater then 1"),
      layer: yup.number().required("Please enter asset layer"),

      asset: yup.object().shape({
        implementation: yup
          .string()
          .required("Please select implementation image "),
        presentation: yup.string().required("Please enter presentation image"),
      }),
    })
  ),
});

export const associatePlayerInTeamValidation = yup.object().shape({
  playerId: yup.string().required("Please select player"),
  teamId: yup.string().required("Team name is required"),
  asset: yup.array().of(
    yup.object().shape({
      name: yup.string().required("Please enter asset name"),
      supply: yup
        .number()
        .required("Please enter asset supply")
        .min(1, "Asset supply should be greater then 1"),
      layer: yup.number().required("Please enter asset layer"),
      rarity: yup
        .number()
        .required("Please enter asset rarity")
        .min(1, "Rarity should be greater then 1")
        .max(10, "Rarity should be 10 or lower then 10"),
      asset: yup.object().shape({
        implementation: yup
          .string()
          .required("Please select implementation image "),
        presentation: yup.string().required("Please enter presentation image"),
      }),
    })
  ),
});

export const associateHeroInMovieValidation = yup.object().shape({
  heroId: yup.string().required("Please select player"),
  movieId: yup.string().required("Team name is required"),
  asset: yup.array().of(
    yup.object().shape({
      name: yup.string().required("Please enter asset name"),
      supply: yup
        .number()
        .required("Please enter asset supply")
        .min(1, "Asset supply should be greater then 1"),
      layer: yup.number().required("Please enter asset layer").nullable(),
      rarity: yup
        .number()
        .required("Please enter asset rarity")
        .min(1, "Rarity should be greater then 1")
        .max(10, "Rarity should be 10 or lower then 10"),
      asset: yup.object().shape({
        implementation: yup
          .string()
          .required("Please select implementation image "),
        presentation: yup.string().required("Please enter presentation image"),
      }),
    })
  ),
});
