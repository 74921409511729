import React, { useState, useEffect } from 'react';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import { Typography } from '@mui/material';
import styles from './styles.module.scss';
import {
    Box, Card, CardContent,
    TextField, Button, FormControl,
    Grid, Select, MenuItem, InputLabel, FormHelperText
} from '@material-ui/core';
import { yearList } from '@utils/constent'
import Loader from '@components/Loader';
import { useDispatch } from 'react-redux';
import { showToast } from '@redux/action/index';
import { useNavigate } from 'react-router-dom';
import { getImageUrl, postApiReq, getApiReq } from 'src/utils/ApiHandlers';
import { Editor } from "@tinymce/tinymce-react";
import { createEntertainmentPackValidation } from '@utils/validation';
import { isYupError, parseYupError } from "@utils/Yup";
import moment from 'moment';

const initialState = {
    agencyId: '',
    movieId: '',
    collectionName: '',
    asset: '',
    year: 2022,
    supply: 1,
    description: '',
    cardInside: 1,
    chain: 'POLYGON',
    price: 0.1,
    packDrop: moment().utc().format('YYYY-MM-DDThh:mm')
}

const supplyRegx = /^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/;

export default function CreateAndUpadateEntertainmentPacks() {
    const [form, setForm] = useState(initialState)
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [errors, setErrors] = useState({})
    const [agencyList, setAgencyList] = useState([])
    const [moviesList, setMoviesList] = useState([])

    const uploadFile = async (e) => {
        let data = new FormData();
        data.append('asset', e.target.files[0])
        let image = await getImageUrl(data)
        setForm({
            ...form, [e.target.name]: image
        });

        setErrors({
            ...errors, [e.target.name]: ''
        });

    }
    useEffect(() => {
        getAgencyList();
    }, [form.year]);

    useEffect(() => {
        if (form.agencyId)
            getMoviesList()
    }, [form.agencyId]);

    const getMoviesList = () => {
        setIsLoading(true);
        getApiReq(`/admin/get-agency-movies/${form.agencyId}`)
            .then(res => {
                if (res) {
                    setMoviesList(res.data)
                    setIsLoading(false);
                }
                else {
                    setIsLoading(false);
                }
            }).catch(e => { console.log(e) })
    };

    const getAgencyList = () => {
        setIsLoading(true);
        getApiReq(`/admin/get-year-agency?year=${form.year}`)
            .then(res => {
                if (res) {
                    setAgencyList(res.data)
                    setIsLoading(false);
                }
                else {
                    setIsLoading(false);
                }
            }).catch(e => { console.log(e) })
    };

    const handleSubmit = async () => {
        let data = {
            ...form,
            cardInside: Number(form.cardInside),
            price: Number(form.price),
            supply: Number(form.supply) * Number(form.cardInside),
            packDrop: `${form.packDrop}:00.000Z`,
        }
    
            setIsLoading(true);
            try {
                setErrors({});
                await createEntertainmentPackValidation.validate(data, {
                    abortEarly: false,
                });
                postApiReq(`/admin/entertainment-pack`, data)
                    .then(res => {
                        if (res.status) {
                            dispatch(showToast({ message: 'Entertainment Pack created successfully', type: 'success' }))
                            navigate('/entertainment-packs')
                            setIsLoading(false);
                        }
                        else {
                            dispatch(showToast({ message: res.error, type: 'error' }))
                            setIsLoading(false);
                        }
                    }).catch(e => { setIsLoading(false); console.log(e) })
            } catch (error) {
                if (isYupError(error)) {
                    setErrors(parseYupError(error));
                } else {

                }
                setIsLoading(false)
            }
        
    };

    const handleChange = (e) => {
        let { name, value } = e.target
        setForm({
            ...form, [name]: value
        })
        setErrors({
            ...errors, [e.target.name]: ''
        })
    }

    const handleSupplyChange = (e) => {
        if (supplyRegx.test(e.target.value) || e.target.value === '') {
            setForm({
                ...form, [e.target.name]: e.target.value
            });
            setErrors({
                ...errors, [e.target.name]: ''
            })

        }
    }

    return (
        <React.Fragment>
            <TableContainer component={Paper} className={styles.tableContainer}>
                <Typography className={styles.heading}>{'Create Entertainment Pack'}</Typography>
                <p className={styles.paragraph}>A series of games or contests that make up a single unit of competition.</p>
                <Box mt={3}>
                    <Card>
                        <CardContent >
                            <Box ml={10}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} lg={5} >
                                        <TextField
                                            error={errors.collectionName}
                                            margin="normal"
                                            fullWidth
                                            type="text"
                                            label="Packs name"
                                            name="collectionName"
                                            value={form.collectionName}
                                            helperText={errors.collectionName}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} lg={5}>
                                        <TextField
                                            margin="normal"
                                            fullWidth
                                            error={errors.supply}
                                            type="text"
                                            label="Entertainment Pack Supply"
                                            name="supply"
                                            helperText={errors.supply}
                                            value={form.supply}
                                            onChange={handleSupplyChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} lg={5}>
                                        <TextField
                                            error={errors.cardInside}
                                            margin="normal"
                                            fullWidth
                                            type="text"
                                            label="Number of Cards Inside Pack"
                                            name="cardInside"
                                            helperText={errors.cardInside}
                                            value={form.cardInside}
                                            onChange={handleSupplyChange}
                                        />

                                    </Grid>
                                    <Grid mt={2} item xs={12} lg={5}>
                                        <FormControl fullWidth error={Boolean(errors.year)}>
                                            <InputLabel id="demo-simple-select-label">Agency year</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={form.year}
                                                name="year"
                                                label="Agency year"
                                                onChange={handleChange}
                                            >
                                                {yearList.map((item, index) => <MenuItem key={index} value={item}>{item}</MenuItem>)}
                                            </Select>
                                            <FormHelperText>{errors.year}</FormHelperText>
                                        </FormControl>
                                    </Grid>

                                    <Grid mt={2} item xs={12} lg={5}>
                                        <FormControl fullWidth error={Boolean(errors.agencyId)}>
                                            <InputLabel id="demo-simple-select-label">Select Agency</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={form.agencyId}
                                                label="Select Agency"
                                                name="agencyId"
                                                onChange={handleChange}
                                            >
                                                {agencyList.map((item, index) => <MenuItem key={index} value={item._id}>{item.agencyName}</MenuItem>)}
                                            </Select>
                                            <FormHelperText>{errors.agencyId}</FormHelperText>
                                        </FormControl>
                                    </Grid>

                                    <Grid mt={2} item xs={12} lg={5}>
                                        <FormControl fullWidth error={Boolean(errors.movieId)}>
                                            <InputLabel id="demo-simple-select-label">Select Movie</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={form.movieId}
                                                label="Select Movie"
                                                name="movieId"
                                                onChange={handleChange}
                                            >

                                                {moviesList.map((item, index) => <MenuItem key={index} value={item._id}>{item.movieName}</MenuItem>)}
                                            </Select>
                                            <FormHelperText>{errors.movieId}</FormHelperText>
                                        </FormControl>
                                    </Grid>

                                    <Grid mt={2} item xs={12} lg={5}>
                                        <FormControl fullWidth error={Boolean(errors.chain)}>
                                            <InputLabel id="demo-simple-select-label">Select Chain</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={form.chain}
                                                label="Select Chain"
                                                name="chain"
                                                onChange={handleChange}
                                            >
                                                <MenuItem value="POLYGON">POLYGON</MenuItem>

                                            </Select>
                                            <FormHelperText>{errors.chain}</FormHelperText>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} lg={5}>
                                        <TextField
                                            error={errors.price}
                                            margin="normal"
                                            fullWidth
                                            helperText={errors.chain}
                                            type="text"
                                            label="Price"
                                            name="price"
                                            value={form.price}
                                            onChange={handleSupplyChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} lg={5}>
                                        <TextField
                                            id="datetime-local"
                                            label="Pack Drop"
                                            type="datetime-local"
                                            sx={{ width: '100%' }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            name='packDrop'
                                            value={form.packDrop}
                                            onChange={handleChange}
                                            error={errors.packDrop}
                                            helperText={errors.packDrop}
                                        />
                                    </Grid>
                                    <Grid className={styles.imagesContainer} item xs={12} lg={5}>
                                        <Button
                                            className={styles.uploadButton}
                                            variant="outlined"
                                            component="label"
                                        >
                                            Upload Entertainment Image
                                            <input
                                                type="file"
                                                name="asset"
                                                onChange={uploadFile}
                                                hidden
                                            />
                                        </Button>
                                        {form.asset && <img className={styles.image} src={form.asset} alt="asset" />}
                                        {errors.asset && <small className='errormsg'>{errors.asset}</small>}
                                    </Grid>

                                    <Grid item xs={10} lg={10}>
                                        <Editor
                                            initialValue={form.content}
                                            init={{
                                                height: 300,
                                                menubar: false,
                                                placeholder: 'Description',
                                                plugins: [
                                                    "advlist autolink lists link image charmap print preview anchor",
                                                    "searchreplace visualblocks code fullscreen",
                                                    "insertdatetime media table paste code help wordcount",
                                                ],
                                                toolbar:
                                                    `undo redo | image | formatselect | bold italic backcolor | \
                                                    alignleft aligncenter alignright alignjustify | \
                                                    bullist numlist outdent indent | removeformat | help`,
                                            }}
                                            onEditorChange={(content, editor) => {
                                                if (content) {
                                                    setForm({
                                                        ...form,
                                                        description: content,
                                                    });
                                                    setErrors({
                                                        ...errors, description: ''
                                                    })
                                                }
                                            }}
                                        />
                                        {errors.description && <small className='errormsg'>{errors.description}</small>}
                                    </Grid>
                                    <Grid item xs={11} align="right">
                                        <Button size="large" variant="contained" onClick={handleSubmit}>Submit</Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </CardContent>
                    </Card>
                </Box>
                <Loader isLoading={isLoading} />
            </TableContainer >
        </React.Fragment >
    );
}
