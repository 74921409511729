
import { Navigate } from "react-router-dom";


const ProtectedRoute = ({ children }) => {

  // return localStorage.hasOwnProperty('user') ? children : <Navigate to="/" />
  return children
};

export default ProtectedRoute
