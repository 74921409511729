export const yearList = [
    2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032, 2033, 2034, 2035, 2036, 2037, 2038, 2039, 2040

];

export const sportList = [{
    id: '6399b1cad5ebe005e02058af',
    sportName: 'NFL'
},
{
    id: '63997142e372acf6f3a9c4f5',
    sportName: 'CRICKET'
}]
