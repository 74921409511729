import {
  SET_USER_DETAILS,
} from "../action/actionConstants";

const initialState = {
  userToken: null,
  user: null,
  userCollection: null,
};

const UserDetails = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_USER_DETAILS:
      return {
        ...state,
        user: action.payload
      }
    default:
      return state;
  }
};

export default UserDetails;
