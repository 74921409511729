import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Typography } from '@mui/material';
import styles from './styles.module.scss';
import {
  Box, Card, CardContent,
  TextField, InputAdornment, SvgIcon, Button,
  Grid
} from '@material-ui/core';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import SearchIcon from '@mui/icons-material/Search';
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from '@components/Loader';
import { useNavigate } from 'react-router-dom';
import { getApiReq } from 'src/utils/ApiHandlers';
import { useDispatch } from 'react-redux';
import { showToast } from '@redux/action/index';
import { imageHandler } from 'src/utils/formatter';


export default function EntertainmentPacks() {
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate();
  const [search, setSearch] = useState('')
  const dispatch = useDispatch();
  const [entertainmentPackList, setEntertainmentPackList] = useState([])

  const getEntertainmentPackList = () => {
    setIsLoading(true);
    getApiReq(`/admin/get-entertainment-pack?search=${search}`)
      .then(res => {
        if (res) {
          setEntertainmentPackList(res.data)
          setIsLoading(false);
        }
        else {
          dispatch(showToast({ message: res.error, type: 'error' }))
          setIsLoading(false);
        }
      }).catch(e => { console.log(e) })
  };


  useEffect(() => {
    getEntertainmentPackList();
  }, [search]);
  return (
    <React.Fragment>
      <TableContainer component={Paper} className={styles.tableContainer}>
        <Typography className={styles.heading}>Entertainment Packs</Typography>
        <p className={styles.paragraph}>The Entertainment on Marketplace, ranked by volume,floor price and other statistics.</p>
        <Box mt={3}>
          <Card>
            <CardContent>
              <Grid container spacing={3} alignItems="center">
                <Grid item xs={7}>
                  <TextField
                    fullWidth
                    onChange={(e) => setSearch(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SvgIcon
                            fontSize="small"
                            color="action"
                          >
                            <SearchIcon />
                          </SvgIcon>
                        </InputAdornment>
                      )
                    }}
                    placeholder="Search"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={5} align="right">
                  <Button onClick={() => navigate('/create-entertainment-pack')} size="large" variant="contained" startIcon={<AddCircleIcon />}>Create Entertainment Packs</Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
        {/* <InfiniteScroll
          dataLength={length}
          next={getMoreList}
          hasMore={true}
          style={{ overflow: 'none' }}
        > */}

        <Table className='table' sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell >#</TableCell>
              <TableCell className={styles.tabletitle} align="center">Asset Image</TableCell>
              <TableCell className={styles.tabletitle} align="center">Packs Name</TableCell>
              <TableCell className={styles.tabletitle} align="center">Available Pack</TableCell>
              <TableCell className={styles.tabletitle} align="center">Number of Cards Inside Pack</TableCell>
              <TableCell className={styles.tabletitle} align="center">Agency Name</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>

            {entertainmentPackList?.map((item, index) => (
              <TableRow
                key={index}
                className={styles.tableRow}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell  >{index + 1}</TableCell>
                <TableCell className={styles.dataView} align="center" ><img alt='' className={styles.roundImg} src={imageHandler(item.asset, 3)} /></TableCell>
                <TableCell className={styles.dataView} align="center" >{item.collectionName}</TableCell>
                <TableCell className={styles.dataView} align="center" >{item.supply / item.cardInside || 0}</TableCell>
                <TableCell className={styles.dataView} align="center" >{item.cardInside}</TableCell>
                <TableCell className={styles.dataView} align="center" >{item?.agency?.agencyName || '-'}</TableCell>
              </TableRow>
            ))}

          </TableBody>
        </Table>
        {entertainmentPackList.length < 1 &&
          <Box align={'center'} w='100%' p={5} >
            <Typography variant="body1" textAlign="center" color="textSecondary">Data not Available</Typography>
          </Box>}

        {/* </InfiniteScroll> */}
        <Loader isLoading={isLoading} />
      </TableContainer >

    </React.Fragment >
  );
}
