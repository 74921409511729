
import Cookies from "js-cookie";
import { zeroAddress } from "./env";
export const isUserLoggedIn = () => {
  return Boolean(Cookies.get("user-data"))
}
export const cutString = (string, length) => {
  if (typeof string != 'string')
    string = JSON.stringify(string)
  if (string?.length < length) return string;

  return string?.substring(0, length) + ('...')
}


