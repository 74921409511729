import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import './index.css';
import { BrowserRouter as Router } from "react-router-dom";
import rootSaga from '@redux/saga';
import { createStore, applyMiddleware, compose } from 'redux';
import rootReducer from '@redux/rootReducers';
import { Provider } from 'react-redux';
import createSagaMiddleware from 'redux-saga';
import ReportWebVitals from './ReportWebVitals';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  MuiInputLabel: {
    styleOverrides: {
      root: {
        fontWeight: 'bold',
        "&.Mui-focused": {
          color: 'rgba(0, 0, 0, 0.87)',
        },
      },
    }
  },
  MuiTextField: {
    defaultProps: {
      variant: 'standard',
      borderColor: '#4d5268'
    },
  },
  palette: {
    primary: {
      main: '#4d5268',
    }
  },
});

const sagaMiddleware = createSagaMiddleware();

function configureStore(initialState) {
  const middleware = [sagaMiddleware];

  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const store = createStore(rootReducer, initialState, composeEnhancers(applyMiddleware(...middleware)));

  sagaMiddleware.run(rootSaga);

  return store;
}

const store = configureStore();


ReactDOM.render(
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <Router>
        <App />
      </Router>
    </Provider>
  </ThemeProvider>,
  document.getElementById("root")
);

// ReactDOM.render(
//   <React.StrictMode>
//     <BrowserRouter history={createBrowserHistory()}>
//       <App />
//     </BrowserRouter>
//   </React.StrictMode>,
//   document.getElementById('root')
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
ReportWebVitals();
