import { Alert as MuiAlert, Snackbar } from "@material-ui/core";
import React, { useState, useEffect, lazy, Suspense } from "react";
import Navbar from "./Navbar";
import { HIDE_TOAST } from "@redux/action/actionConstants";
import { useDispatch, useSelector } from "react-redux";
import SignIn from "@pages/SignIn";
import { Routes as RouteWrapper, Route, useLocation } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import { getUserDetails } from "@redux/action";
import AuthContext from "@context/AuthContext";
import Loader from "@components/Loader/index";
import EntertainmentPacks from "./containers/entertainmentpacks/index";
import CreateAndUpadateEntertainmentPacks from "./containers/entertainmentpacks/createEntertainmentPacks";
import Agency from "./containers/agency/index";
import CreateAndUpdateAgency from "./containers/agency/createAndUpdateAgency";
import Movies from "./containers/movies/index";
import CreateAndUpdateMovie from "./containers/movies/createAndUpdateMovie";
import CreateAndUpdateHero from "./containers/superHeroes/createAndUpdateSuperhero";
import SuperHeroes from "./containers/superHeroes/index";
import MovieHeroes from "./containers/movies/movieHeros";
import AssociateHero from "./containers/movies/associateHero";

const Dashboard = lazy(() => import("@containers/dashboard"));
const ForgetPassword = lazy(() => import("@pages/ForgetPassword"));
const ResetPassword = lazy(() => import("@pages/ResetPassword"));
const NotFound = lazy(() => import("@pages/NotFound"));
const Profile = lazy(() => import("@pages/Profile"));
const Tournament = lazy(() => import("@containers/tournament"));
const Player = lazy(() => import("@containers/player"));
const Teams = lazy(() => import("@containers/teams"));
const CreateAndUpdateTournament = lazy(() =>
  import("@containers/tournament/createAndUpdateTournament")
);
const EarningsSales = lazy(() => import("@containers/earningsSales"));
const CreateAndUpadatTeam = lazy(() =>
  import("@containers/teams/createAndUpadatTeam")
);
const FanCard = lazy(() => import("@containers/fanCard/index"));
const CollectiblePacks = lazy(() =>
  import("@containers/collectiblePacks/index")
);
const AddPlayer = lazy(() => import("./containers/player/addPlayer"));
const TeamPlayer = lazy(() => import("./containers/teams/teamPlayer"));
const AssociatePlayer = lazy(() =>
  import("./containers/teams/associatePlayer")
);
const CreateFanCard = lazy(() => import("./containers/fanCard/createFanCard"));
const CreateCollectiblePack = lazy(() =>
  import("@containers/collectiblePacks/createCollectiblePack")
);
const UploadTeamAsset = lazy(() => import("@containers/teams/uploadTeamAsset"));
const UserCardRank = lazy(() => import("@containers/userCardRank"));

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const App = () => {
  const dispatch = useDispatch();
  const [user, setUser] = useState({
    isUserLogin: false,
    token: "",
    user_id: "",
  });
  const location = useLocation();

  useEffect(() => {
    if (localStorage.hasOwnProperty("user")) {
      dispatch(getUserDetails());
      const USER = localStorage.getItem("user");
      setUser((prev) => {
        return {
          ...prev,
          isUserLogin: true,
          token: USER.token,
          user: USER.user,
        };
      });
    }
  }, []);

  const { type, message, isVisible } = useSelector(
    (state) => state.toastReducer
  );

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch({
      type: HIDE_TOAST,
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <>
      <Snackbar
        open={isVisible}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <Alert onClose={handleClose} severity={type} sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
      <AuthContext.Provider
        value={{
          ...user,
          setUser,
        }}
      >
        <Suspense fallback={<Loader isLoading={true} />}>
          <Navbar>
            <RouteWrapper>
              <Route exact path="/" element={<SignIn />} />
              <Route
                exact
                path="/forget-password"
                element={<ForgetPassword />}
              />
              <Route
                exact
                path="/reset-password/:token"
                element={<ResetPassword />}
              />
              <Route
                path="/dashboard"
                exact
                element={
                  <ProtectedRoute>
                    <Dashboard />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/profile"
                element={
                  <ProtectedRoute>
                    <Profile />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/sport-card"
                exact
                element={
                  <ProtectedRoute>
                    {" "}
                    <FanCard />{" "}
                  </ProtectedRoute>
                }
              />
              <Route
                path="/create-sport-card"
                exact
                element={
                  <ProtectedRoute>
                    {" "}
                    <CreateFanCard />{" "}
                  </ProtectedRoute>
                }
              />
              <Route
                path="/collectible-packs"
                exact
                element={
                  <ProtectedRoute>
                    {" "}
                    <CollectiblePacks />{" "}
                  </ProtectedRoute>
                }
              />
              <Route
                path="/create-collectible-pack"
                exact
                element={
                  <ProtectedRoute>
                    {" "}
                    <CreateCollectiblePack />{" "}
                  </ProtectedRoute>
                }
              />
              <Route
                path="/tournament"
                exact
                element={
                  <ProtectedRoute>
                    {" "}
                    <Tournament />{" "}
                  </ProtectedRoute>
                }
              />
              <Route
                path="/add-player"
                exact
                element={
                  <ProtectedRoute>
                    {" "}
                    <AddPlayer />{" "}
                  </ProtectedRoute>
                }
              />
              <Route
                path="/player"
                exact
                element={
                  <ProtectedRoute>
                    {" "}
                    <Player />{" "}
                  </ProtectedRoute>
                }
              />
              <Route
                path="/teams"
                exact
                element={
                  <ProtectedRoute>
                    {" "}
                    <Teams />{" "}
                  </ProtectedRoute>
                }
              />
              <Route
                path="/manage-player/:id/:teamName/:tournamentId"
                exact
                element={
                  <ProtectedRoute>
                    {" "}
                    <TeamPlayer />{" "}
                  </ProtectedRoute>
                }
              />
              <Route
                path="/associate-player/:id/:teamName/:tournamentId"
                exact
                element={
                  <ProtectedRoute>
                    {" "}
                    <AssociatePlayer />{" "}
                  </ProtectedRoute>
                }
              />
              <Route
                path="/upload-asset/:id/:teamName"
                exact
                element={
                  <ProtectedRoute>
                    {" "}
                    <UploadTeamAsset />{" "}
                  </ProtectedRoute>
                }
              />
              <Route
                path="/earnings-sales"
                exact
                element={
                  <ProtectedRoute>
                    {" "}
                    <EarningsSales />{" "}
                  </ProtectedRoute>
                }
              />
              <Route
                path="/create-tournament"
                exact
                element={
                  <ProtectedRoute>
                    {" "}
                    <CreateAndUpdateTournament />{" "}
                  </ProtectedRoute>
                }
              />
              <Route
                path="/create-team"
                exact
                element={
                  <ProtectedRoute>
                    {" "}
                    <CreateAndUpadatTeam />{" "}
                  </ProtectedRoute>
                }
              />
              <Route
                path="/user-card-ranks"
                exact
                element={
                  <ProtectedRoute>
                    {" "}
                    <UserCardRank />{" "}
                  </ProtectedRoute>
                }
              />
              <Route
                path="/entertainment-packs"
                exact
                element={
                  <ProtectedRoute>
                    {" "}
                    <EntertainmentPacks />{" "}
                  </ProtectedRoute>
                }
              />
              <Route
                path="/create-entertainment-pack"
                exact
                element={
                  <ProtectedRoute>
                    {" "}
                    <CreateAndUpadateEntertainmentPacks />{" "}
                  </ProtectedRoute>
                }
              />
              <Route
                path="/agency"
                exact
                element={
                  <ProtectedRoute>
                    {" "}
                    <Agency />{" "}
                  </ProtectedRoute>
                }
              />
              <Route
                path="/create-agency"
                exact
                element={
                  <ProtectedRoute>
                    {" "}
                    <CreateAndUpdateAgency />{" "}
                  </ProtectedRoute>
                }
              />
              <Route
                path="/movies"
                exact
                element={
                  <ProtectedRoute>
                    {" "}
                    <Movies />{" "}
                  </ProtectedRoute>
                }
              />
              <Route
                path="/create-movies"
                exact
                element={
                  <ProtectedRoute>
                    {" "}
                    <CreateAndUpdateMovie />{" "}
                  </ProtectedRoute>
                }
              />

              <Route
                path="/superheroes"
                exact
                element={
                  <ProtectedRoute>
                    {" "}
                    <SuperHeroes />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/create-superheroes"
                exact
                element={
                  <ProtectedRoute>
                    <CreateAndUpdateHero />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/update-superheroes/:id"
                exact
                element={
                  <ProtectedRoute>
                    <CreateAndUpdateHero />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/movie-heroes/:id/:movieName/:agencyId"
                exact
                element={
                  <ProtectedRoute>
                    {" "}
                    <MovieHeroes />{" "}
                  </ProtectedRoute>
                }
              />
              <Route
                path="/associate-hero/:id/:movieName/:agencyId"
                exact
                element={
                  <ProtectedRoute>
                    {" "}
                    <AssociateHero />{" "}
                  </ProtectedRoute>
                }
              />

              <Route path="/*" element={<NotFound />} />
            </RouteWrapper>
          </Navbar>
        </Suspense>
      </AuthContext.Provider>
    </>
  );
};

export default App;
