import { select, put, takeLatest, all } from 'redux-saga/effects';
import { getApiReq } from 'src/utils/ApiHandlers';

function* getUserDetails() {
  if (localStorage.hasOwnProperty('user')) {
    const response = yield getApiReq('/admin/get-user-details')
    try {
      if (response.status) {
        yield put({
          type: "SET_USER_DETAILS", payload: response.data
        })
      }
      else {
        yield put({
          type: "SHOW_TOAST", payload: ({ type: 'error', message: response.error })
        });
      }

    } catch (e) {
      yield put({ type: "SET_USER_DETAILS", payload: null });
    }
  }
}



function* actionWatcher() {
  yield takeLatest('GET_USER_DETAILS', getUserDetails);
}


export default function* rootSaga() {
  yield all([
    actionWatcher(),
  ]);
}