import React, { useState, useEffect } from 'react';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import { Typography } from '@mui/material';
import styles from './styles.module.scss';
import {
    Box, Card, CardContent,
    TextField, Button,
    Grid,
} from '@material-ui/core';
import Loader from '@components/Loader';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { showToast } from '@redux/action/index';
import { useNavigate } from 'react-router-dom';
import { getImageUrl, postApiReq, getApiReq } from 'src/utils/ApiHandlers';
import { createSuperHeroValidation } from '@utils/validation';
import { isYupError, parseYupError } from "@utils/Yup";
import Checkbox from '@mui/material/Checkbox';
const initialState = {
    name: '',
    profile: '',
    assetType: [{ name: '', required: false, }],
}

export default function CreateAndUpdateHero() {
    const { id } = useParams();
    const [form, setForm] = useState(initialState)
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [errors, setErrors] = useState({})

    const uploadFile = async (e, index, type, assetType) => {
        let data = new FormData();
        data.append('asset', e.target.files[0])
        let image = await getImageUrl(data);
        if (index === 0 || index) {
            let temp = form[assetType].slice(0);
            temp[index]['asset'][type] = image
            setForm({
                ...form, [assetType]: temp
            })
            if (errors[assetType]) {
                let tempError = errors[assetType].slice(0)
                tempError[index]['asset'][type] = ''
                setErrors({
                    ...errors, [assetType]: tempError,
                })
            }
        }
        else {
            setForm({
                ...form, [e.target.name]: image
            })
            setErrors({
                ...errors, [e.target.name]: ''
            })
        }


    }

    const getHerosDetails = () => {
        setIsLoading(true);
        getApiReq(`/admin/hero-details/${id}`)
            .then(res => {
                if (res) {
                    setForm(res.data)
                    setIsLoading(false);
                }
                else {
                    dispatch(showToast({ message: res.error, type: 'error' }))
                    setIsLoading(false);
                }
            }).catch(e => { console.log(e) })
    };

    useEffect(() => {
        if (id) {
            getHerosDetails();
        }
    }, [id])



    const handleSubmit = async () => {
        setIsLoading(true);
        let str = id ? '/admin/update-hero-asset' : '/admin/create-heros';
        try {
            setErrors({});
            await createSuperHeroValidation.validate(form, {
                abortEarly: false,
            });
            postApiReq(str, { ...form, id: id || '' })
                .then(res => { 
                    if (res.status) {
                        dispatch(showToast({ message:`SuperHero ${id?'Update':'created'} successfully`, type: 'success' }))
                        navigate('/superheroes')
                        setIsLoading(false);
                    }
                    else {
                        dispatch(showToast({ message: res.error, type: 'error' }))
                        setIsLoading(false);
                    }
                }).catch(e => { console.log(e) })
        } catch (error) {
            if (isYupError(error)) {
                setErrors(parseYupError(error));
            } else {

            }
            setIsLoading(false)
        }
    };

    const handleChange = (e) => {
        let { name, value } = e.target
        setForm({
            ...form, [name]: value
        })
        setErrors({
            ...errors, [name]: ''
        })
    }

    // Assets 
    const handleAssetChange = (e, index,) => {
        let temp = form['assetType'].slice(0);
        if (e.target.name === 'name') {
            temp[index][e.target.name] = e.target.value
            setForm({
                ...form, ['assetType']: temp
            })
            if (errors['asset']) {
                let tempError = errors['asset'].slice(0);

                tempError[index][e.target.name] = ''
                setErrors({
                    ...errors, ['asset']: tempError
                })
            }

        } else {
            temp[index][e.target.name] = e.target.checked
            setForm({
                ...form, ['assetType']: temp
            })
            if (errors['asset']) {
                let tempError = errors['asset'].slice(0);
                tempError[index][e.target.name] = ''
                setErrors({
                    ...errors, ['asset']: tempError
                })
            }

        }
    }

    const RemoveAsset = (index, type) => {
        let temp = form[type].slice(0);
        temp.splice(index, 1)
        setForm({
            ...form, [type]: temp
        })
    }

    const addAsset = (type) => {
        let temp = form[type].slice(0);
        temp.push({ name: '', required: '', })
        setForm({
            ...form, [type]: temp
        })
    }


    return (
        <React.Fragment>
            <TableContainer component={Paper} className={styles.tableContainer}>
                <Typography className={styles.heading}>{id ? 'Update SuperHero' : 'Create SuperHero'}</Typography>
                <p className={styles.paragraph}>A series of games or contests that make up a single unit of competition.</p>
                <Box mt={3}>
                    <Card>
                        <CardContent >
                            <Box ml={10}>
                                <Grid container spacing={0}>
                                    <Grid container spacing={2} >
                                        <Grid item xs={12} lg={5} >
                                            <TextField
                                                margin="normal"
                                                fullWidth
                                                error={errors.name}
                                                helperText={errors.name}
                                                type="text"
                                                label='Hero name'
                                                name="name"
                                                value={form.name}
                                                onChange={handleChange}
                                                disabled={Boolean(id)}

                                            />

                                        </Grid>

                                        {!id && <Grid className={styles.imagesContainer} item xs={12} lg={5} xl={5} mt={2} >
                                            <Button className={styles.uploadButton} variant="outlined" component="label">Upload profile image
                                                <input
                                                    type="file"
                                                    hidden
                                                    name='profile'
                                                    onChange={uploadFile}
                                                />
                                            </Button>
                                            <Grid item xs={12} lg={5} xl={5} mt={2} >
                                                {form.profile && <img className={styles.image} src={form.profile} alt='' />}
                                                {errors.profile && <small className='errormsg'>{errors.profile}</small>}
                                            </Grid>
                                        </Grid>

                                        }

                                    </Grid>

                                    <Grid container spacing={3}>
                                        <Grid item xs={12} lg={10} >
                                            <p className={styles.subheading}>Hero Asset Type</p>
                                            <Grid container spacing={2} alignItems='center' gap={2}>
                                                <Grid item xs={3} lg={5}>
                                                    <Typography>Asset Name</Typography>
                                                </Grid>
                                                <Grid item xs={3} lg={3}>
                                                    <Typography >Required for Assemble</Typography>
                                                </Grid>
                                            </Grid>
                                            {form?.assetType?.map((item, index) => (
                                                <Grid key={index} container spacing={2} alignItems='center' gap={2}>
                                                    <>
                                                        <Grid item xs={3} lg={5} >
                                                            <TextField
                                                                margin="normal"
                                                                fullWidth
                                                                error={errors.assetType?.[index]?.name}
                                                                type="text"
                                                                label="Asset name"
                                                                helperText={errors.assetType?.[index]?.name}
                                                                name="name"
                                                                value={item.name}
                                                                onChange={(e) => handleAssetChange(e, index)}
                                                                disabled={Boolean(id)}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={3} lg={1} >
                                                            <Checkbox
                                                                value={item.required}
                                                                onChange={(e) => handleAssetChange(e, index)}
                                                                checked={item.required}
                                                                name="required"

                                                            />
                                                            <Typography className='errormsg' ><small>{errors.assetType?.[index]?.required}</small></Typography>
                                                        </Grid>
                                                    </>
                                                    {
                                                        !id && form?.assetType?.length > 1 && <Typography mb={2} onClick={() => RemoveAsset(index, 'assetType')} className={styles.removeButton}>Remove asset</Typography>
                                                    }
                                                </Grid>
                                            ))}
                                            {!id && <Grid item mt={2} xs={3} align="left">
                                                <Typography className={styles.addButton} onClick={() => addAsset('assetType')}>Add more asset type</Typography>
                                            </Grid>}
                                        </Grid>

                                    </Grid>


                                    <Grid item xs={11} align="right">
                                        <Button size="large" variant="contained" onClick={handleSubmit}>{id ? 'UpdateAsset' : 'Submit'}</Button>

                                    </Grid>
                                </Grid>
                            </Box>
                        </CardContent>
                    </Card>
                </Box>
                <Loader isLoading={isLoading} />
            </TableContainer >
        </React.Fragment >
    );
}
