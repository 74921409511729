import React, { useState, useEffect } from 'react';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import { Typography } from '@mui/material';
import styles from './styles.module.scss';
import {
    Box, Card, CardContent,
    TextField, Button, FormHelperText,
    Grid, TextareaAutosize, FormControl, InputLabel, OutlinedInput
} from '@material-ui/core';
import { yearList, } from '@utils/constent'
import Select from '@mui/material/Select';
import Loader from '@components/Loader';
import MenuItem from '@mui/material/MenuItem';
import { useParams } from 'react-router-dom';
import { getApiReq, postApiReq } from 'src/utils/ApiHandlers';
import { useDispatch } from 'react-redux';
import { showToast } from '@redux/action/index';
import { useNavigate } from 'react-router-dom';
import { createAgencyValidation } from '@utils/validation';
import { isYupError, parseYupError } from "@utils/Yup";
import moment from 'moment';

const initialState = {
    agencyName: '',
    year: moment().format('YYYY'),
    description: '',
}

export default function CreateAndUpdateAgency() {
    const { id } = useParams();
    const [form, setForm] = useState(initialState)
    const navigate = useNavigate();
    const [errors, setErrors] = useState({})
    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useState(false)

    const handleSubmit = async () => {
        setIsLoading(true);
        try {
            setErrors({});
            await createAgencyValidation.validate(form, {
                abortEarly: false,
            });
            postApiReq(`/admin/add-agency`, form)
                .then(res => {
                    if (res.status) {
                        dispatch(showToast({ message: 'Agency created successfully', type: 'success' }))
                        navigate('/agency')
                        setIsLoading(false);
                    }
                    else {
                        dispatch(showToast({ message: res.error, type: 'error' }))
                        setIsLoading(false);
                    }
                }).catch(e => { console.log(e) })
        } catch (error) {
            if (isYupError(error)) {
                setErrors(parseYupError(error));
            } else {

            }
            setIsLoading(false)
        }
    };

    const handleChange = (e) => {
        let { name, value } = e.target
        setForm({
            ...form, [name]: value
        })
        setErrors({
            ...errors, [name]: ''
        })
    };

    return (
        <React.Fragment>
            <TableContainer component={Paper} className={styles.tableContainer}>
                <Typography className={styles.heading}>Create Agency</Typography>
                <p className={styles.paragraph}>A series of games or contests that make up a single unit of competition.</p>
                <Box mt={3}>
                    <Card>
                        <CardContent>
                            <Box ml={10}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} lg={5} >
                                        <TextField
                                            margin="normal"
                                            error={!!errors.agencyName}
                                            helperText={errors.agencyName}
                                            fullWidth
                                            type="text"
                                            label="Agency Name"
                                            name="agencyName"
                                            value={form.agencyName}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} lg={5} mt={2}>
                                        <FormControl fullWidth error={errors.year}>
                                            <InputLabel id="demo-simple-select">Agency year</InputLabel>
                                            <Select
                                                labelId="demo-simple-select"
                                                id="demo-simple-select"
                                                name="year"
                                                value={form.year}
                                                input={<OutlinedInput label="Agency year" />}
                                                label="Agency_year"
                                                onChange={handleChange}
                                            >
                                                {yearList.map((item, index) => <MenuItem key={index} value={item}>{item}</MenuItem>)}
                                            </Select>
                                            <FormHelperText>{errors.year}</FormHelperText>
                                        </FormControl>

                                    </Grid>

                                    <Grid item xs={12} lg={10} >
                                        <TextareaAutosize
                                            aria-label="description"
                                            minRows={7}
                                            placeholder="description"
                                            name="description"
                                            onChange={handleChange}
                                            style={{ width: '100%' }}
                                        />
                                        {errors.description && <small className='errormsg'>{errors.description}</small>}
                                    </Grid>

                                    <Grid item xs={11} align="right">
                                        <Button size="large" onClick={handleSubmit} variant="contained" >Submit</Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </CardContent>
                    </Card>
                </Box>
                <Loader isLoading={isLoading} />
            </TableContainer >
        </React.Fragment >
    );
}
