import * as types from './actionConstants';

export const setTopCategory = (payload) => ({
  type: types.SET_TOP_CATEGORY,
  payload
});

export const setTopCreators = (payload) => ({
  type: types.SET_TOP_CREATORS,
  payload
});

export const setTopTrending = (payload) => ({
  type: types.SET_TOP_TRENDING,
  payload
});

export const setTopBlog = (payload) => ({
  type: types.SET_TOP_BLOG,
  payload
});

export const getTopCategory = () => ({
  type: types.GET_TOP_CATEGORY
});

export const getTopCreators = () => ({
  type: types.GET_TOP_CREATORS

});
export const getTopTrending = () => ({
  type: types.GET_TOP_TRENDING
});

export const getTopBlog = () => ({
  type: types.GET_TOP_BLOG
});

/**
 * Notification Popup
 */
export const showToast = (payload) => ({
  type: types.SHOW_TOAST,
  payload
})

/**wallet redux */
export const setMetamastWalletInfo = (payload) => ({
  type: types.SET_METAMAST_WALLET_INFO,
  payload
});

export const setCoinbaseWalletInfo = (payload) => ({
  type: types.SET_COINBASE_WALLET_INFO,
  payload
});

/* User redux */
export const setUserInfo = (payload) => ({
  type: types.SET_USER_INFO,
  payload
});


/* get userDetails */
export const getUserDetails = (payload) => ({
  type: types.GET_USER_DETAILS,
  payload
});