import { combineReducers } from 'redux';
import ToastReducer from "./reducers/ToastReducer";
import UserDetails from './reducers/UserDetails';

const rootReducer = combineReducers({
  toastReducer: ToastReducer,
  userDetails: UserDetails,
});

export default rootReducer;

