const ENV = {
  BASE_URL:
    process.env.NODE_ENV === "development"
      ? "http://localhost:9001"
      : "https://api.fanzinga.com",
};

export const contracts = {
  POLYGON: {
    WyvernProxyRegistry: "0x50D0013Eb3c02a76e42C00b3Eed9aD12800223E4",
    WyvernExchange: "0xe1848dAf61035AaDC3D0740fA94155feFc857765",
    WyvernTokenTransferProxy: "0x6f723f49eE5b2740C6BD81cB8100324c4787cac7",
    AssetContractShared: "0xCC760eB4A6D36BAA3439C354E04C0B3FaA49ADB4",
    MarketV2: "0x40D86C4298c987fdb0c4B49F980766fa20e4a53F",
    Lootbox: "0xaA7d9B436aBcB464A3976AC51482c57879c00f4E",
  },
};

export const feeRecipient = "0xd2645b8E1DE56d53236bd0EB2728Efa9Ae710003";
export const zeroAddress = "0x0000000000000000000000000000000000000000";
export const noAddress = null;

/**
 * Deployed exchange contract on network
 */
export const chainId = {
  ETHEREUM: "0x3",
  POLYGON: "0x13881",
};

export const tokenAddress = {
  ETHEREUM: {
    ETH: zeroAddress,
    WETH: "0xc778417E063141139Fce010982780140Aa0cD5Ab",
  },
  POLYGON: {
    MATIC: zeroAddress,
    WMATIC: "0x9c3C9283D3e44854697Cd22D3Faa240Cfb032889",
  },
};

// export const noAddress = null

export default ENV;
